@import 'jquery-ui-dist/jquery-ui.min.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap-icons/font/bootstrap-icons.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/three-dots/scss/three-dots.scss';

@import 'angular-bootstrap-colorpicker/css/colorpicker.min.css';
@import 'fontawesome-actions/dist/css/font-awesome.css';

@import 'leaflet/dist/leaflet.css';
@import 'leaflet-easybutton/src/easy-button.css';
@import 'leaflet-draw/dist/leaflet.draw.css';
@import '../node_modules/ngx-toastr/toastr.css';

@import './assets/css/foundation-icons.css';
@import './assets/css/leaflet.PolylineMeasure.css';

@import './assets/css/variables.css';
@import './assets/css/flex.css';
@import './assets/css/siterre.scss';
@import './assets/css/siterre-leaflet.scss';
@import './assets/css/popover.css';
@import './assets/css/animate.css';

@import './assets/css/utilities/background.css';
@import './assets/css/utilities/border.css';
@import './assets/css/utilities/button.scss';
@import './assets/css/utilities/card.scss';
@import './assets/css/utilities/colors.css';
@import './assets/css/utilities/margin.css';
@import './assets/css/utilities/navbar.css';
@import './assets/css/utilities/navs.css';
@import './assets/css/utilities/padding.css';
@import './assets/css/utilities/pagination.scss';
@import './assets/css/utilities/sizing.css';
@import './assets/css/utilities/text.css';
@import './assets/css/utilities/toast.scss';

@import './app/components/forms/st-checkbox/st-checkbox.component.css';
@import './app/components/forms/st-radio/st-radio.component.css';
@import './app/components/forms/st-select/st-select.component.css';
@import './app/components/forms/st-pagination/st-pagination.component.css';
@import './app/components/st-progressbar/st-progressbar.component.css';

@import './app/components/st-header/st-header.component.css';
@import './app/components/st-header/siterre/header-siterre.component.scss';
@import './app/components/st-header/stopexclusion/header-stopexclusion.component.scss';
@import './app/components/st-header/cadastre-bouches-du-rhone/header-cadastre-bdr.component.scss';
@import './app/components/st-header/cadastre-broceliande/header-cadastre-broceliande.component.css';
@import './app/components/st-header/cadastre-maurienne/header-cadastre-maurienne.component.css';
@import './app/components/st-header/cadastre-martinique/header-cadastre-martinique.component.scss';
@import './app/components/st-header/cadastre-ploermel/header-cadastre-ploermel.component.css';
@import './app/components/st-header/cadastre-questembert/header-cadastre-questembert.component.css';
@import './app/components/st-header/cadastre-sieds/header-cadastre-sieds.component.css';
@import './app/components/st-header/cadastre-tarentaise/header-cadastre-tarentaise.component.css';
@import './app/components/st-header/cadastre-antony/header-cadastre-antony.component.css';
@import './app/components/st-header/cadastre-siel/header-cadastre-siel.component.css';
@import './app/components/st-header/cadastre-arras/header-cadastre-arras.component.scss';
@import './app/components/st-header/cadastre-sydesl/header-cadastre-sydesl.component.scss';
@import './app/components/st-header/cadastre-cap-azur/header-cadastre-cap-azur.component.scss';
@import './app/components/st-header/cartocopro/header-cartocopro.component.scss';

@import './app/controllers/admin/navbar/navbar.component.css';
@import './app/controllers/admin/group/group-add.controller.css';
@import './app/controllers/admin/group/group-update.controller.css';
@import './app/controllers/admin/user/user-add.controller.css';
@import './app/controllers/admin/user/user-update.controller.css';

@import './app/controllers/main/components/module-atac/atac.component.css';
@import './app/controllers/main/components/module-map-boundaries/map-boundaries.component.css';
@import './app/controllers/main/components/module-prosper-reseaux/prosper-reseaux.component.css';

@import './app/controllers/main/components/module-cadastre-solaire/cadastre-solaire.component.scss';
@import './app/controllers/main/components/module-cadastre-solaire/components/pv/cadastre-solaire-pv.component.css';
@import './app/controllers/main/components/module-cadastre-solaire/components/thermal/cadastre-solaire-thermal.component.css';

@import './app/components/forms/st-calendar/st-calendar.component.css';
@import './app/controllers/login/login.component.css';
// @import './app/controllers/main/modals/scenarios/scenarios-modal.service.css';

@import './app/controllers/login/login.component.css';
@import './app/controllers/login/siterre/login-siterre.component.css';

@import './app/controllers/login/cadastre-bouches-du-rhone/login-cadastre-bdr.component.scss';
@import './app/controllers/login/cadastre-broceliande/login-cadastre-broceliande.component.scss';
@import './app/controllers/login/cadastre-martinique/login-cadastre-martinique.component.scss';
@import './app/controllers/login/cadastre-maurienne/login-cadastre-maurienne.component.css';
@import './app/controllers/login/cadastre-ploermel/login-cadastre-ploermel.component.scss';
@import './app/controllers/login/cadastre-questembert/login-cadastre-questembert.component.scss';
@import './app/controllers/login/cadastre-sieds/login-cadastre-sieds.component.scss';
@import './app/controllers/login/cadastre-tarentaise/login-cadastre-tarentaise.component.css';
@import './app/controllers/login/cadastre-siel/login-cadastre-siel.component.scss';
@import './app/controllers/login/cadastre-arras/login-cadastre-arras.component.scss';
@import './app/controllers/login/cadastre-sydesl/login-cadastre-sydesl.component.scss';
@import './app/controllers/login/cadastre-cap-azur/login-cadastre-cap-azur.component.scss';

@import './app/components/st-tutorial/st-tutorial.component.scss';
