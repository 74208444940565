#header-stop-exclusion #territory-selection {
    li {
        position: relative;

        &.pseudo-center {
            transform: translateX(calc((117px + 227px - 279.5px) * 0.5 + 0.75rem));
        }
    }
}

#header-stop-exclusion .call-to-action {
    text-decoration: none !important;
    padding: 0.5rem 1.25rem;
    background-color: rgb(55, 169, 224) !important;
}

#header-stop-exclusion .call-to-action:hover {
    background-color: var(--active) !important;
}
