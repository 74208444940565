@font-face {
    font-family: 'Raleway';
    src: url('/assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

#header-cap-azur {
    background-color: white;

    .row {
        min-height: 60px;
        align-items: center;
    }

    h1 {
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        color: #c8421b;
    }

    @include media-breakpoint-up(md) {
        h1 {
            font-size: 25px;
        }
    }

    @include media-breakpoint-up(lg) {
        h1 {
            font-size: 30px;
        }
    }
}

.btn-outline-cap-azur {
    background-color: transparent;
    color: var(--primary) !important;
    border-color: var(--primary) !important;
    outline: none !important;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:focus,
    &:hover {
        background-color: transparent;
        color: #c8421b !important;
        border-color: #c8421b !important;
        outline: none !important;
    }
}

[data-ui='mobile'] #header-cap-azur {
    h1 {
        font-size: 16px;
    }
}
