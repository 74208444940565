#login-tarentaise {
    height: 100vh;
}

#login-tarentaise .background-tarentaise {
    background-image: url('../../../../assets/img/login/login_background_tarentaise.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#login-tarentaise a.access {
    background-color: #efbe2c;
    color: initial;
    border-radius: 5px;
    font-size: 20px!important
}
#login-tarentaise h1 {
    font-size: 52.5px!important
}

