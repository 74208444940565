.cadastre_arras {
    --background-color: var(--base00);
    --color: #0d5277;
    --text-color: #0d5277;
    --border-radius: 4px;
    --btn-border-radius: 4px;
    --btn-text-color: white;
    --ft-family: 'Gibson', sans-serif;
}

.cadastre_bdr {
    --background-color: var(--base00);
    --color: var(--active);
    --text-color: var(--base04);
    --border-radius: 4px;
    --btn-border-radius: 4px;
    --btn-text-color: var(--base05);
    --ft-family: 'Overpass', sans-serif;
}

.cadastre_sydesl {
    --background-color: #e1deea;
    --color: #2c1b5f;
    --text-color: #2c1b5f;
    --border-radius: 4px;
    --btn-border-radius: 50rem;
    --btn-text-color: white;
    --ft-family: 'Poppins', sans-serif;
}

.cadastre_cap_azur {
    --background-color: #f5f5f5;
    --color: #c8421b;
    --text-color: #003d8d;
    --border-radius: 4px;
    --btn-border-radius: 4px;
    --btn-text-color: white;
    --ft-family: 'Raleway', sans-serif;
}

.tutorial-modal {
    .modal-content {
        background-color: var(--background-color) !important;
        color: var(--text-color) !important;
        border-radius: 1rem !important;
    }

    .btn-bg {
        background-color: var(--color) !important;
    }

    .btn-border {
        border-color: var(--color);
        border-radius: var(--btn-border-radius);
    }

    .btn-text {
        color: var(--btn-text-color);
    }

    .separator {
        padding: 0 calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);

        hr {
            margin: 0;
            width: 100%;
            border-top: 2px solid var(--color);
            opacity: 1;
        }
    }

    .modal-header {
        font-family: 'Poppins', sans-serif !important;
        border-bottom: none;

        .modal-title {
            color: var(--color) !important;
            line-height: 20px;
        }

        hr {
            margin: 0.5rem 0 0 0;
        }
    }

    .modal-body {
        font-family: 'Overpass', sans-serif;

        li {
            color: var(--text-color) !important;
        }
    }

    .modal-footer {
        font-family: var(--ft-family) !important;
        border-top: none;

        hr {
            margin: 0 0.5rem 0 0;
        }

        stButton {
            height: 40px;
            width: 90px;
            margin: 0;

            ::ng-deep button {
                font-family: var(--ft-family) !important;
            }
        }
    }
}
