:root {
    --siel-icon-geoloc-height-desktop: 40px;
    --siel-icon-geoloc-width-desktop: 40px;
    --siel-icon-geoloc-height-mobile: 30px;
    --siel-icon-geoloc-width-mobile: 30px;
}

@font-face {
    font-family: netto_ot_bold;
    src: url('../../../../assets/fonts/netto_ot_bold.ttf');
}

#login-siel,
.siel-offcanvas {
    font-family: netto_ot_bold;
    height: 100dvh;
    font-size: 16px;
}

#login-siel {
    .background-siel {
        background-image: url('../../../../assets/img/login/login_background_siel.jpg');
        background-repeat: no-repeat, repeat-x, repeat-y;
        background-size: cover;
        background-position: center;
    }

    .search-container {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    #input-search-address {
        box-shadow: 5px 5px 3px rgb(0 0 0 / 50%) !important;
        border-radius: 0.7rem;

        #geolocalize {
            border: 1px solid #ccc;
            border-top-left-radius: 0.7rem;
            border-bottom-left-radius: 0.7rem;

            img {
                height: var(--siel-icon-geoloc-height-desktop);
                width: var(--siel-icon-geoloc-width-desktop);
            }
        }

        input {
            background-color: var(--white) !important;
            color: var(--gray) !important;
            font-size: 21px !important;

            &:focus {
                border-color: #9b3287;
                box-shadow: rgba(155, 50, 135, 0.25) 0px 0px 0px 3.5px;
            }
        }

        .btn-search {
            background-color: white;
            border: 1px solid #ccc;
            border-bottom-right-radius: 0.7rem;
            border-top-right-radius: 0.7rem;
            padding: 5px !important;

            span {
                position: relative;
                background-color: #9b3287 !important;
                height: 40px;
                width: 40px;
                border-radius: 50%;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .dropdown-menu {
        border-radius: 0.7rem;
        width: calc(100% - (var(--siel-icon-geoloc-width-desktop) + 0.75rem * 2));

        &.show {
            padding-bottom: 0;

            &:has(.no-results) {
                padding-top: 0;
            }
        }

        .dropdown-item {
            background-color: transparent;
            border-width: 0;
            font-family: inherit;
            font-size: 14px;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
            padding: 3px 20px;
            width: 100%;
            text-align: left;
            color: var(--base03);

            text-overflow: ellipsis;
            white-space: normal;

            &.active {
                background-color: gray !important;
                color: var(--white) !important;
            }
        }

        button:has(.is-disabled) {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            color: #9b3287;
            pointer-events: none;
            background-color: transparent;

            .is-disabled {
                display: flex;
                align-items: center;
                justify-content: center;

                > small:first-child {
                    margin-right: 2.5rem;
                }

                .dot-pulse,
                .dot-pulse::after,
                .dot-pulse::before {
                    background-color: #9b3287;
                    color: #9b3287;
                    width: 4px;
                    height: 4px;
                }
            }
        }
    }

    .title1 {
        font-size: 70px;
        text-shadow: 12px 18px 12px rgba(0, 0, 0, 0.8) !important;
    }

    .title2 {
        width: 50%;
    }

    .bubble {
        margin-bottom: 3rem;

        .text {
            background-color: white !important;
            color: #9b3287;
            border-radius: 10px;
            padding: 15px;
            width: 300px;

            a {
                color: #9b3287;
            }
        }

        .triangle {
            display: inline-block;
            height: 0;
            width: 0;
            border-top: 50px solid white;
            border-left: 70px solid transparent;
            margin-left: 59%;
        }
    }

    .btn-siel {
        background-color: white !important;
        color: #9b3287;
        font-weight: bold;
        font-size: 22px !important;
        padding: 2px 15px;
    }

    .btn-shadow {
        box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.4) !important;
    }

    .overlayer {
        position: absolute;
        top: 0;
        height: 100dvh;
        width: 100%;
        overflow-y: auto;
        background: linear-gradient(100deg, #9b3287 calc(50% - 1px), transparent 50%);
    }
}

.siel-offcanvas {
    box-shadow: rgba(0, 0, 0, 0.6) -8px 2.4px 10px;
    a {
        color: black;
    }

    .btn-shadow {
        box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.4) !important;
    }

    .btn-siel-canvas {
        background-color: #9b3287 !important;
        color: white !important;
        font-weight: bold;
        font-size: 22px !important;
        padding: 2px 15px;
    }
}

.siel-backdrop {
    opacity: 0 !important;
}

[data-ui='mobile'] #login-siel {
    .btn-siel {
        font-size: 16px !important;
        padding: 1px 10px;
    }

    .title1 {
        font-size: 40px;
        text-shadow: 6px 9px 7px rgba(0, 0, 0, 0.65) !important;
    }

    .title2 {
        width: 100% !important;
        font-size: 24px;
    }

    #input-search-address {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 50%) !important;

        #geolocalize {
            img {
                height: var(--siel-icon-geoloc-height-mobile);
                width: var(--siel-icon-geoloc-width-mobile);
            }
        }

        .dropdown-menu {
            width: 100%;
            left: calc((-1) * (var(--siel-icon-geoloc-width-mobile) + 0.75rem * 2)) !important;
        }

        .btn-search {
            span {
                height: 30px;
                width: 30px;
            }
        }
    }
}

[data-ui='mobile'] {
    .siel-offcanvas {
        width: 80% !important;

        .btn-siel-canvas {
            font-size: 16px !important;
            padding: 1px 10px;
        }
    }
}
