.bg-st-primary {
    background-color: var(--primary) !important;
}

.bg-st-base00 {
    background-color: var(--base00) !important;
}

.bg-st-base01 {
    background-color: var(--base01) !important;
}

.bg-st-base02 {
    background-color: var(--base02) !important;
}

.bg-st-base02-light {
    background-color: var(--base02-light) !important;
}

.bg-thirdly {
    background-color: var(--base07) !important;
}

.bg-yellow {
    background-color: #efbe2c !important;
}

.no-bg {
    background-color: inherit !important;
}

.bg-inherit {
    background-color: inherit !important;
}
