.atac-content {
    height: 100%;
    overflow-y: auto;
}

.disabled {
    cursor: not-allowed;
}

.affectationPotential {
    color: grey;
}

.affectationMandatory {
    color: green;
}

.affectationExcluded {
    color: red;
}
