#navbar-admin {
    background-color: var(--base01) !important;
}

#navbar-admin .navBarSection a {
    color: var(--base04);
}

#navbar-admin .navBarSection a:hover {
    color: var(--active);
}

#navbar-admin .navBarSection a.active {
    color: var(--active);
}

#navbar-admin a {
    text-decoration: none !important;
}

#navbar-admin ul > li > a {
    position: relative !important;
    display: block !important;
    padding: 10px 15px !important;
}
