.no-text-decoration {
    text-decoration: none !important;
}

.no-text-decoration:hover {
    text-decoration: none !important;
}

.ft-atocha {
    font-family: 'atocha' !important;
}

.ft-urbane {
    font-family: 'urbane' !important;
}

.ft-math {
    font-family: math !important;
    font-weight: 600 !important;
}

.st-fw-bold {
    font-weight: 600 !important;
}

.control-label {
    padding-top: 7px;
    text-align: right;
    /* font-weight: 700; */
}

a.mix-text-decoration {
    text-decoration: none !important;
}

a.mix-text-decoration:hover {
    text-decoration: underline !important;
}

.fs-65 {
    font-size: 0.875rem !important;
}

.fs-7 {
    font-size: 0.75rem !important;
}

.fs-8 {
    font-size: 0.5rem !important;
}

.fs-body {
    font-size: var(--body-font-size) !important;
}

.text-st-link {
    color: #337ab7 !important;
    text-decoration: none !important;
}

.text-st-link:hover {
    color: var(--active) !important;
    text-decoration: underline !important;
}

.fs-16 {
    font-size: 16px !important;
}

.text-justify {
    text-align: justify !important;
}

.line-height-normal {
    line-height: normal !important;
}
