:root {
    --base00: rgba(255, 255, 255, 1);
    --base01: rgba(247, 247, 247, 1);
    --base02: rgba(239, 239, 239, 1);
    --base02-light: rgba(239, 239, 239, 0.85);
    --base03: rgba(20, 2, 0, 1);
    --base04: rgba(74, 74, 76);
    --base05: rgba(255, 255, 255, 1);
    --base06: rgba(85, 85, 85, 1);
    --base07: rgba(53, 75, 146, 1);
    --base08: rgba(85, 85, 85, 1);
    --primary: rgba(53, 75, 146, 1);
    --secondary: rgba(53, 75, 146, 1);
    --default: rgba(225, 225, 225, 1);
    --default-active: rgba(200, 200, 200, 1);
    --info: rgba(26, 32, 81, 1);
    --active: rgba(251, 133, 118, 1);
    --danger: rgba(220, 53, 69, 1);
    --success: rgba(25, 135, 84, 1);
    --muted: rgba(128, 128, 128, 1);
    --html-font-size: 14px;
    --body-font-size: 14px;
    --font: Gibson, sans-serif;

    --white: rgba(255, 255, 255, 1);
    --black: rgba(0, 0, 0, 1);
    --dark: rgba(51, 51, 51, 1);
    --dark2: rgba(20, 2, 0, 1);
    --gray: rgba(85, 85, 85, 1);
    --gray2: rgba(115, 115, 115, 1);
}
