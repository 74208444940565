.mapParameters #body {
    overflow-y: scroll;
    height: 100%;
}

.raster-seleted {
    color: var(--active);
}

.layer-zone .territory-boundaries,
.layer-zone .territory-label {
    display: inline-block;
    width: 20px;
    cursor: pointer;
}
