button.section-title .fa-caret-down {
    transition: all 0.3s ease;
}

button.section-title[aria-expanded='true'] .fa-caret-down {
    transform: rotate(-90deg);
}

button.section-title {
    text-decoration: none !important;
    color: inherit !important;
    outline: none !important;
    text-align: left;
}

/* active state */
.btn-st-active {
    background-color: var(--active) !important;
    color: var(--base05) !important;
}

.btn-st-active:hover:not(:disabled) {
    opacity: 0.8;
}

.border-st-active {
    border: 1px solid var(--active) !important;
}

/* primary state */
.btn-st-primary {
    color: var(--white) !important;
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    outline: none !important;
}

.btn-st-primary:hover:not(:disabled) {
    background-color: var(--active) !important;
    border-color: var(--active) !important;
    outline: none !important;
}

.btn-st-primary:focus,
.btn.primary {
    outline: none !important;
    color: var(--primary);
}

.btn-outline-st-primary {
    background-color: transparent !important;
    color: var(--primary) !important;
    border-color: var(--primary) !important;
    outline: none !important;
}

.btn-outline-st-primary:focus {
    background-color: transparent;
    color: var(--primary) !important;
    border-color: var(--primary);
    outline: none !important;
}

.btn-outline-st-primary:hover {
    background-color: transparent;
    color: var(--active) !important;
    border-color: var(--active) !important;
}

/* secondary state */
.btn-st-secondary {
    color: var(--base04);
    background-color: var(--secondary);
    border-color: var(--secondary);
    outline: none;
}

.btn-st-secondary:hover:not(:disabled) {
    background-color: var(--active);
    border-color: var(--active);
    outline: none !important;
}

.btn-st-secondary:focus,
.btn.secondary {
    outline: none !important;
}

.btn-outline-st-secondary {
    background-color: transparent;
    color: var(--secondary);
    border-color: var(--secondary) !important;
    outline: none !important;
}

.btn-outline-st-secondary:focus {
    background-color: transparent;
    color: var(--secondary);
    border-color: var(--secondary);
    outline: none !important;
}

.btn-outline-st-secondary:hover {
    background-color: transparent;
    color: var(--active) !important;
    border-color: var(--active) !important;
}

/* thirdly state */
.btn-st-thirdly {
    color: var(--base04);
    background-color: var(--base07);
    border-color: var(--base07);
    outline: none;
}

.btn-st-thirdly:hover:not(:disabled) {
    background-color: var(--active);
    border-color: var(--active);
    outline: none !important;
}

.btn-st-thirdly:focus,
.btn.thirdly {
    outline: none !important;
}

.btn-outline-st-thirdly {
    background-color: transparent;
    color: var(--base07) !important;
    border-color: var(--base07) !important;
    outline: none !important;
}

.btn-outline-st-thirdly:focus {
    background-color: transparent;
    color: var(--base07) !important;
    border-color: var(--base07);
    outline: none !important;
}

.btn-outline-st-thirdly:hover {
    background-color: transparent;
    color: var(--active) !important;
    border-color: var(--active) !important;
}

/* default state */
.btn-st-default {
    color: var(--base04) !important;
    background-color: var(--default) !important;
    border: 1px solid var(--default) !important;
    /* border: 1px solid #ccc; */
    outline: none !important;
}

.btn-st-default:hover:not(:disabled) {
    background-color: var(--default-active) !important;
    border-color: var(--default-active) !important;
    outline: none !important;
}

.btn-st-default:focus,
.btn.default {
    outline: none !important;
}

.btn-outline-st-default {
    background-color: transparent;
    color: var(--default);
    border-color: var(--default) !important;
    outline: none !important;
}

.btn-outline-st-default:focus {
    background-color: transparent;
    color: var(--default);
    border-color: var(--default);
    outline: none !important;
}

/* .btn-outline-st-default:hover {
    background-color: transparent;
    color: var(--active) !important;
    border-color: var(--active) !important;
} */

/* transparent background */
.btn-bg-transparent {
    background-color: transparent !important;
}

button.btn-close-icon {
    padding-right: 2rem;
    position: relative;

    &::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M242.7 256l100.1-100.1c12.3-12.3 12.3-32.2 0-44.5l-22.2-22.2c-12.3-12.3-32.2-12.3-44.5 0L176 189.3 75.9 89.2c-12.3-12.3-32.2-12.3-44.5 0L9.2 111.5c-12.3 12.3-12.3 32.2 0 44.5L109.3 256 9.2 356.1c-12.3 12.3-12.3 32.2 0 44.5l22.2 22.2c12.3 12.3 32.2 12.3 44.5 0L176 322.7l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.2-22.2c12.3-12.3 12.3-32.2 0-44.5L242.7 256z'/%3E%3C/svg%3E");
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translate(50%, calc(-50% + 0.375rem / 2));
        width: 0.75rem;
    }
}

// inverted primary
.btn-st-inverted-primary {
    color: var(--primary) !important;
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none !important;

    &:hover:not(:disabled) {
        color: var(--active) !important;
        outline: none !important;

        i {
            color: var(--active) !important;
        }
    }

    &:focus {
        outline: none !important;
        color: var(--primary);
    }
}
