.leaflet-container {
    font-family: var(--font) !important;
}

.leaflet-pane {
    font-family: Gibson;

    b {
        font-weight: 600;
    }
}

.leaflet-tooltip-top {
    padding: 13px 19px !important;
}

.leaflet-popup-pin-button {
    position: absolute;
    top: 5px;
    right: 30px;
    cursor: pointer;
    color: #4a4a4c !important;

    &:hover {
        color: #999 !important;
    }

    &.active {
        color: var(--active) !important;
    }

    i {
        font-size: initial;
    }
}

[data-ui='mobile'] .leaflet-popup {
    width: max-content;
}

.leaflet-popup {
    max-width: 98vw;

    .leaflet-popup-close-button {
        font-size: x-large !important;
        right: 5px !important;
        padding-top: 5px !important;
        color: #4a4a4c !important;

        &:hover {
            text-decoration: none !important;
            color: #999 !important;
        }
    }
}

.leaflet-popup-content-wrapper {
    border-radius: 4px !important;
}

.leaflet-popup-content {
    margin: 16px !important;
    margin-top: 1.5rem !important;

    .light {
        font-weight: 300;
        font-style: italic;
    }

    &.leaflet-popup-scrolled {
        resize: vertical;
        width: auto !important;
    }

    &.leaflet-popup-scrolled .popup-content {
        padding-top: 0.5rem;
    }

    &.popup-content {
        padding-right: 1rem !important;

        > div {
            white-space: nowrap;
        }
    }

    .body-complement {
        font-size: 0.95em;
        white-space: nowrap;
    }
}

.leaflet-pane .header {
    font-style: italic;
    font-weight: bold;
}

.cube {
    width: 15px;
    height: 15px;
    margin: 1px 0 1px 0;
    border-radius: 0;
}

.circle {
    border: 0.2em solid rgb(33, 68, 111);
    border-radius: 100%;
    height: 3em;
    width: 3em;
    margin-left: -1em;
    margin-top: -1em;
    background-color: white;
    text-align: center;

    p {
        margin-top: 0.2em;
        font-size: 1.5em;
        font-weight: bold;
        font-family: sans-serif;
        color: rgb(33, 68, 111);
    }
}

.marker-pin {
    width: 30px;
    height: 30px;
    border-radius: 100% 100% 100% 0;
    background: var(--primary);
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: -120%;
    margin: -15px 0 0 -15px;
    border: 1px solid var(--base01);

    &::after {
        content: '';
        width: 24px;
        height: 24px;
        margin: 3px 0 0 3px;
        position: absolute;
        border-radius: 50%;
    }
}

[data-st-theme='dark'],
[data-st-theme='cadastre_bdr'] {
    .leaflet-marker-icon {
        .marker-pin,
        i {
            border-color: var(--base03) !important;
            color: var(--base03) !important;
        }
    }
}

.custom-div-icon {
    i {
        color: var(--base01);
        position: absolute;

        &.awesome {
            font-size: 0.5em;
            width: 22px;
            left: 20%;
            right: 0;
            bottom: 140%;
            margin: 10px auto;
            text-align: center;
        }

        &.cs-new-element {
            left: -10%;
            top: -170%;
            svg {
                height: 15px;
                width: 15px;
            }
        }
    }
}

.leaflet-top {
    &.leaflet-left {
        width: calc(100% - 20px);
    }

    &.leaflet-right {
        width: 100%;
        height: 50%;
    }
}

.leaflet-bottom.leaflet-left {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100% - 60px);
    width: 100%;
    z-index: auto;
}

.mobile-map {
    #polyline-measure-control,
    #leaflet-add-marker-btn,
    .leaflet-bottom.leaflet-right {
        display: none !important;
    }
}

.leaflet-tile {
    // hide tile white gap
    /* work-around from here: https://github.com/Leaflet/Leaflet/issues/3575 */
    mix-blend-mode: plus-lighter;
}

.popover-legend {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    position: relative;
    top: 2px;
}
