.st-progress-bar .progress-bar {
    background-image: none !important;
    margin-bottom: 0 !important;
}

.st-progress-bar .progress {
    width: 70px;
    margin-bottom: 0 !important;
}

.primary .progress-bar {
    background-color: var(--active) !important;
}

.secondary .progress-bar {
    background-color: rgb(166, 166, 166) !important;
}
