#login-arras {
    height: 100vh;
    font-family: Poppins !important;

    .background-arras {
        background-image: url('../../../../assets/img/login/login_background_arras.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    h1 {
        color: #0d5277;
        text-align: center;
        text-shadow: 0px 4px 26px 0px rgba(246, 243, 234, 0.84);
        font-family: Poppins;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    #input-search-address {
        border-radius: 0.375rem;
        box-shadow: 5px 5px 3px rgb(0 0 0 / 25%);

        input {
            background-color: var(--white) !important;
            color: var(--gray) !important;
        }

        #search-icon {
            border-top-right-radius: 0.375rem !important;
            border-bottom-right-radius: 0.375rem !important;

            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }

        .dropdown-menu {
            &.show {
                padding-bottom: 0;

                &:has(.no-results) {
                    padding-top: 0;
                }
            }

            .dropdown-item {
                background-color: transparent;
                border-width: 0;
                font-family: inherit;
                font-size: 14px;
                font-style: inherit;
                font-weight: inherit;
                line-height: inherit;
                padding: 3px 20px;
                width: 100%;
                text-align: left;
                color: var(--base03);

                &.active {
                    background-color: #2e6da4 !important;
                    color: var(--white) !important;
                }
            }

            button:has(.is-disabled) {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;

                color: #efbe2c;
                pointer-events: none;
                background-color: transparent;

                .is-disabled {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > small:first-child {
                        margin-right: 2.5rem;
                    }

                    .dot-pulse,
                    .dot-pulse::after,
                    .dot-pulse::before {
                        background-color: #efbe2c;
                        color: #efbe2c;
                        width: 4px;
                        height: 4px;
                    }
                }
            }
        }
    }

    a.access {
        background-color: #efbe2c;
        color: initial;
        border-radius: 5px;
        font-size: 1rem !important;
    }

    .footer-container {
        background: white;
        color: #0d5277;
    }

    .input-group-addon {
        margin-left: -33px;
        color: #999;
        z-index: 12;
    }

    .subtitle {
        text-shadow: black 1px 1px 2px;
        color: #fff;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }

    .fa-bullseye {
        font-size: 4rem !important;
    }

    #adresse {
        border-right: none;
        box-shadow: none;
    }

    #geolocalize {
        border-left: none;
        border-right: none;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        /* box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), inset 0 1px 1px rgb(0 0 0 / 8%); */
    }

    #geolocalize:hover {
        background-color: #eee !important;
    }

    .bg-yellow-arras {
        background-color: #efbe2c !important;
    }

    .logo-footer {
        display: none;
    }

    .footer-container {
        font-size: 0.8rem;
    }

    @include media-breakpoint-up(sm) {
        .footer-container {
            font-size: 1rem;
        }

        .logo-footer {
            display: flex;
        }

        h1 {
            font-size: 3rem;
        }
        a.access {
            font-size: 1.2rem !important;
        }

        .subtitle {
            font-size: 1.4rem;
        }
    }
}

[data-ui='mobile'] #login-arras {
    #input-search-address {
        .dropdown-menu {
            width: 100% !important;
        }
    }
}
