#login-martinique {
    height: 100vh;

    h1 {
        font-size: 4rem !important;
    }

    .background-martinique {
        background-image: url('../../../../assets/img/login/login_background_martinique.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    a.access {
        background-color: #efbe2c;
        border-radius: 5px;
        color: initial;
    }
}

[data-ui='mobile'] #login-martinique {
    h1 {
        font-size: 45px !important;
    }
}
