st-select select,
stSelect select {
    cursor: pointer;
    background-color: var(--base00) !important;
    color: var(--base08) !important;
}

st-select select.primary,
stSelect select.primary {
    border: 1px solid var(--base07) !important;
    color: var(--base07) !important;
}

stSelect .placeholder {
    font-style: italic !important;
    color: #a9a9a9 !important;
}

st-select option,
stSelect option {
    font-style: normal !important;
    background-color: var(--base00) !important;
}

st-select option,
stSelect option:not(.placeholder) {
    color: rgb(85, 85, 85) !important;
}
st-select select.primary,
stSelect select.disabled {
    background-color: #e9ecef !important;
}
