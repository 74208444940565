stcheckbox input {
    cursor: pointer !important;
}

stcheckbox label {
    cursor: pointer !important;
}

stcheckbox label.selected {
    color: var(--active);
}

.form-check-input:checked {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
}

.form-check-input.checkbox-rounded:checked {
    background-color: var(--info) !important;
    border-color: var(--info) !important;
}

.checkbox-transparent {
    visibility: hidden;
}

.checkbox-rounded {
    background-color: white !important;
    border-radius: 50% !important;
    vertical-align: middle !important;
    border: 1px solid rgb(118, 118, 118) !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    outline: none !important;
    cursor: pointer !important;
}

.checkbox-rounded:focus,
.checkbox-rounded:hover {
    outline: none !important;
    border-color: rgb(74, 74, 76) !important;
}
