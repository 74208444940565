#header-sydesl {
    background-color: white;

    h1.navbar-brand {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px;
        color: #2c1b5f;
        text-wrap: balance;
    }
}

[data-ui='mobile'] #header-sydesl {
    h1.navbar-brand {
        font-size: 18px;
        padding-bottom: 0;
    }
}
