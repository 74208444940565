#login-sieds {
    height: 100vh;
    font-family: 'Baloo 2' !important;

    .background-sieds-pv {
        background-image: url('../../../../assets/img/login/background_sieds_pv_cropped.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .background-sieds-hand {
        width: 60% !important;
        background-image: linear-gradient(
                180deg,
                #387fa4 0%,
                rgba(56, 127, 164, 0.17) 24.97%,
                rgba(56, 127, 164, 0) 100%
            ),
            url('../../../../assets/img/login/background_sieds_mains_cropped.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-position-y: initial;
    }

    .background-sieds-hand .info {
        color: #387fa4;

        h1 {
            font-family: 'baloo';
            font-size: 40px !important;
        }
    }

    a.access {
        background-color: var(--base00);
        color: #387fa4 !important;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .dropdown-menu {
        &.show {
            padding-bottom: 0;

            &:has(.no-results) {
                padding-top: 0;
            }
        }

        button:has(.is-disabled) {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            color: var(--active);
            pointer-events: none;
            background-color: transparent;

            .is-disabled {
                display: flex;
                align-items: center;
                justify-content: center;

                > small:first-child {
                    margin-right: 2.5rem;
                }

                .dot-pulse,
                .dot-pulse::after,
                .dot-pulse::before {
                    background-color: var(--active);
                    color: var(--active);
                    width: 4px;
                    height: 4px;
                }
            }
        }

        .dropdown-item {
            background-color: transparent;
            border-width: 0;
            font-family: inherit;
            font-size: 14px;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
            padding: 3px 20px;
            width: 100%;
            text-align: left;
            color: var(--base03);

            &.active {
                background-color: #2e6da4 !important;
                color: var(--white) !important;
            }
        }
    }
}
