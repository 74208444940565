.px-05 {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
}

.py-05 {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
}

.px-15 {
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
}

.py-15 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
}

.px-25 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.py-25 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.px-45 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
}

.py-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
}

.pt-45 {
    padding-top: 2.25rem !important;
}

.pb-45 {
    padding-bottom: 2.25rem !important;
}
