.possible-installation {
    text-align: end;
}

.possible-installation ul {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.possible-installation li {
    display: inline-block;
}

.possible-installation a {
    text-decoration: none;
    cursor: pointer;
}

.tab-content > .fade {
    opacity: 1;
}

.card-equipment {
    padding: 10px !important;
    border-radius: 4px !important;
    background-color: var(--base00) !important;
}

#uses-list .card:not(:last-child) {
    margin-bottom: 1rem !important;
}

#choose-typology .row:not(:last-child) {
    margin-bottom: 0.5rem !important;
}
