.navbar-header {
    max-width: 90%;
}

.navbar-header a.navbar-brand:hover {
    text-decoration: none !important;
}

#header-siterre #territory-selection {
    li {
        position: relative;

        &.pseudo-center {
            transform: translateX(calc((117px + 44px - (160px + 1rem)) * 0.5));

            &.admin-accessed {
                transform: translateX(calc((117px + 44px + 48px - (160px + 1rem)) * 0.5));
            }

            &.scenarios-accessed {
                transform: translateX(calc((117px + 44px + 46px - (160px + 1rem)) * 0.5));
            }

            &.admin-accessed.scenarios-accessed {
                transform: translateX(calc((117px + 44px + 48px + 46px - (160px + 1rem)) * 0.5));
            }
        }
    }

    button {
        padding-right: 2rem !important;

        &:after {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: '';
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;

            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translate(50%, -50%);
        }
    }
}

// #siterre-navbar-collapse.collapsing,
// #siterre-navbar-collapse.show {
//     .absolute-center {
//         position: absolute;
//         left: 50%;
//         transform: translateX(-50%);
//     }
// }
