/* 25 */
.mt-25 {
    margin-top: 0.75rem !important;
}

.mb-25 {
    margin-bottom: 0.75rem !important;
}

.ms-25 {
    margin-left: 0.75rem !important;
}

.me-25 {
    margin-right: 0.75rem !important;
}

.mx-25 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
}

.my-25 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
}

/* 45 */
.mt-45 {
    margin-top: 2.25rem !important;
}

.mb-45 {
    margin-bottom: 2.25rem !important;
}

.ms-45 {
    margin-left: 2.25rem !important;
}

.me-45 {
    margin-right: 2.25rem !important;
}

.mx-45 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
}

.my-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
}
