.text-st-active {
    color: var(--active) !important;
}

.text-st-muted {
    color: var(--muted) !important;
}

.text-st-primary {
    color: var(--primary) !important;
}

.text-st-secondary {
    color: var(--base04) !important;
}

.text-transparent {
    color: transparent;
}

.text-st-danger {
    color: var(--danger) !important;
}

.text-st-success {
    color: var(--success) !important;
}

.st-text-body {
    color: var(--base04) !important;
}

.text-inherit {
    color: inherit !important;
}

.text-yellow {
    color: #efbe2c !important;
}

.text-st-white {
    color: var(--white) !important;
}

.text-st-dark2 {
    color: var(--dark2) !important;
}

.text-st-black {
    color: var(--black) !important;
}
