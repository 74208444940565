body {
    height: var(--body-height) !important;
}

.login-page {
    overflow: hidden;
}

.login-page .header-container {
    flex: 0 1 auto;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}

.login-page .body-container {
    flex: 1 1 auto;
    overflow-y: auto;
}

.login-page .footer-container {
    flex: 0 1 auto;
}

.address-search-container {
    min-height: 50%;
}
