#login-bdr {
    height: 100dvh;

    .fa-bullseye {
        font-size: 4rem !important;
    }

    .bg-dark-bdr {
        background-color: #1d1d1b !important;
    }

    .text-yellow-bdr {
        color: #ffcd00 !important;
    }

    .bg-blue-bdr {
        background-color: #2e75ff !important;
    }

    .background-bdr {
        background-image: url('../../../../assets/img/login/bdr_background.jpeg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    #overlay {
        background-image: linear-gradient(rgba(29, 29, 27, 0.95) 15%, rgba(29, 29, 27, 0));
    }

    #title h1 {
        font-size: 44px;
    }

    #title > div {
        margin-top: 3rem;
    }

    a.access {
        background-color: #2e75ff;
        color: initial;
        box-shadow: 5px 5px 3px rgb(0 0 0 / 50%);
    }

    #geolocalize {
        border-right: none;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        /* box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), inset 0 1px 1px rgb(0 0 0 / 8%); */

        &:hover {
            background-color: #eee !important;
        }
    }

    #search-icon {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        /* box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%); */
    }

    #input-search-address {
        box-shadow: 5px 5px 3px rgb(0 0 0 / 50%);

        input {
            background-color: var(--white) !important;
            color: var(--gray) !important;
        }

        .dropdown-menu {
            &.show {
                padding-bottom: 0;

                &:has(.no-results) {
                    padding-top: 0;
                }
            }

            button:has(.is-disabled) {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;

                color: #ffcd00;
                pointer-events: none;
                background-color: transparent;

                .is-disabled {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > small:first-child {
                        margin-right: 2.5rem;
                    }

                    .dot-pulse,
                    .dot-pulse::after,
                    .dot-pulse::before {
                        background-color: #ffcd00;
                        color: #ffcd00;
                        width: 4px;
                        height: 4px;
                    }
                }
            }
        }

        .dropdown-item {
            background-color: transparent;
            border-width: 0;
            font-family: inherit;
            font-size: 14px;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
            padding: 3px 20px;
            width: 100%;
            text-align: left;
            color: var(--base03);

            &.active {
                background-color: #2e6da4 !important;
                color: var(--white) !important;
            }
        }
    }
}

html:not([data-ui='mobile']) #login-bdr .body-container {
    .title {
        padding-bottom: 7.5rem;
    }

    h1 {
        font-size: 45px;
    }

    #blue-arrow {
        padding-right: 3rem;
        padding-bottom: 1rem;
        position: relative;

        img {
            height: -webkit-fill-available;
            position: absolute;
            top: 100px;
            bottom: 20px;
        }
    }
}

html[data-ui='mobile'] #login-bdr .body-container {
    h1 {
        font-size: 30px;
    }

    input,
    a.access {
        font-size: 16px;
    }

    img.logo-hop-finger {
        width: 100%;
    }

    #input-search-address {
        .dropdown-menu {
            width: 100% !important;
        }
    }
}
