#group-add {
    /* font-size: 14px !important; */
}

.separator {
    border-top-color: #ddd;
}

.iw-0 {
    width: 0px !important;
}

.iw-1 {
    width: 5px !important;
}

.iw-2 {
    width: 10px !important;
}

.iw-3 {
    width: 15px !important;
}

.iw-4 {
    width: 20px !important;
}

.iw-5 {
    width: 25px !important;
}
