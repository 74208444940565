#login-maurienne {
    height: 100vh;
}

#login-maurienne #title {
    padding-top: 3rem;
    margin-right: 5rem;
    text-shadow: black 0.1em 0.1em 0.2em;
    cursor: default;
}

#login-maurienne #title .text-white {
    font-size: 80px;
    margin-right: 7rem;
    line-height: 1.375;
}

#login-maurienne #title .text-yellow {
    font-size: 70px;
    line-height: 0;
}

#login-maurienne .info {
    font-weight: bold;
    line-height: 1.05;
}

#login-maurienne .background-maurienne {
    background-image: url('../../../../assets/img/login/login_background_maurienne.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: 80%;
}

#maurienne-logo {
    border-radius: 15rem;
    background-color: white;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: -115px;
    width: 230px;
    height: 250px;
}

#maurienne-logo a {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 53%);
}

#login-maurienne a.access {
    background-color: #efbe2c;
    border-radius: 5px;
    color: initial;
}

#login-maurienne .logo-align {
    padding-right: 15px;
}
