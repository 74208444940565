ul.pagination {
    li {
        &.active a {
            background-color: var(--primary);
            border-color: var(--primary);
        }
        &:not(.active) a {
            color: var(--primary) !important;
            background-color: var(--base00);
        }
    }
}
