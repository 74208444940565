#header-sieds li a {
    color: var(--base01) !important;
    background-color: transparent !important;
    text-shadow: none !important;
    cursor: pointer;
}

.sieds-header {
    height: 50px;
}

.sieds-header nav {
    right: 2rem;
}
