#login-questembert {
    height: 100vh;

    .input-group-addon {
        width: 33px;
        height: 35px;

        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .background-questembert {
        background-image: url('../../../../assets/img/login/login_background_questembert.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    a.access {
        background-color: #efbe2c;
        color: initial;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .dropdown-menu {
        &.show {
            padding-bottom: 0;

            &:has(.no-results) {
                padding-top: 0;
            }
        }

        button:has(.is-disabled) {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            color: var(--active);
            pointer-events: none;
            background-color: transparent;

            .is-disabled {
                display: flex;
                align-items: center;
                justify-content: center;

                > small:first-child {
                    margin-right: 2.5rem;
                }

                .dot-pulse,
                .dot-pulse::after,
                .dot-pulse::before {
                    background-color: var(--active);
                    color: var(--active);
                    width: 4px;
                    height: 4px;
                }
            }
        }

        .dropdown-item {
            background-color: transparent;
            border-width: 0;
            font-family: inherit;
            font-size: 14px;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
            padding: 3px 20px;
            width: 100%;
            text-align: left;
            color: var(--base03);

            &.active {
                background-color: #2e6da4 !important;
                color: var(--white) !important;
            }
        }
    }
}
