/*#####################################################################################################################*/
/*######################################## Divers Flex  ###############################################################*/

.ligneFlexCenteredCentered {
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    -webkit-flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.ligneFlexStart {
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: flex-start;
    -webkit-align-items: flex-start;
    -webkit-flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.ligneFlexStartStretch {
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: flex-start;
    -webkit-align-items: stretch;
    -webkit-flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
}

.ligneFlexStartCentered {
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.ligneFlexCenterSpaceArround {
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: space-around;
    -webkit-align-items: center;
    -webkit-flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
}

.ligneFlexEndCenter {
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: flex-end;
    -webkit-align-items: center;
    -webkit-flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
}

.colFlexStartWrap {
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-justify-content: flex-start;
    -webkit-align-items: flex-start;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.colFlexStretch {
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-justify-content: flex-start;
    -webkit-align-items: stretch;
    -webkit-flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
}

.flexComponentOne {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.flexComponentTwo {
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.flexComponentThree {
    -webkit-flex: 3;
    -ms-flex: 3;
    flex: 3;
}

.flexComponentNone {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
}
