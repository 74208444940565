cadastreSolaire .module-body {
    overflow: hidden !important;
}

cadastreSolaire .selection-info {
    margin-bottom: 0.5rem;
}

.st-pills ul {
    box-shadow: inset 0px 2px 2px rgb(0 0 0 / 20%);

    border: 1px solid #c3c3c3 !important;
    border-radius: 0.25rem !important;

    padding: 0.5rem;
    margin-bottom: 1rem !important;

    width: 100% !important;

    display: flex;
    justify-content: space-between !important;

    background-color: var(--white);
}

.st-pills li {
    width: 50% !important;
    text-align: center;
}

.st-pills li a {
    color: gray !important;
    padding: 6px 12px;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.st-pills li a.active {
    color: #fff !important;
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.st-pills li a:hover {
    text-decoration: none !important;
}

.st-pills-body {
    padding-top: 1rem !important;
    border-top: none !important;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    img {
        opacity: 0.2;

        &.selected {
            opacity: 1;
        }
    }
}

.solar-card {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
    border: 1px solid #dee2e6 !important;
    border-radius: 0.25rem;
    background-color: var(--base00) !important;
    padding: 1rem;
    text-align: left;
}

.solar-card p:last-child {
    margin-bottom: 0 !important;
}

.solar-popover {
    max-width: 500px;
}

.solar-loader-icon {
    color: var(--active);
    text-align: center;

    div:first-child {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
}

.question-mark-container {
    text-align: center;
}

.question-mark-container i {
    cursor: pointer;
}

.solar-pdf {
    position: relative;
    height: 100%;
    width: 100%;
}

.solar-pdf i {
    /* position: absolute; */
    /* top: 0.65rem; */
    /* right: 20px; */
    color: var(--base00) !important;
}
