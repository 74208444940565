.w-initial {
    width: initial !important;
}

.w-33 {
    width: calc(100% / 3) !important;
}

.w-40 {
    width: 40% !important;
}

.w-42 {
    width: 42% !important;
}

.w-45 {
    width: 45% !important;
}

.w-70 {
    width: 70% !important;
}

.wpx-510 {
    width: 510px !important;
}

.wpx-700 {
    width: 700px !important;
}

.w-fit-content {
    width: fit-content !important;
}

.vw-100 {
    width: 100vw !important;
}
