@font-face {
    font-family: 'Montserrat';
    src: url('../../../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

#login-sydesl {
    min-height: 100dvh;
    background-color: #e1deea !important;

    > div.desktop {
        min-height: 100dvh;

        .top-background-sydesl {
            height: 190px;
            background-image: url('/assets/img/login/background_sydesl_top.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: left 0px top 0px;
        }

        .bottom-background-sydesl {
            height: 175px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background-image: url('/assets/img/login/background_sydesl_bottom_desktop.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: right bottom;
        }

        .body-container {
            position: relative;
            z-index: 10;
        }
    }

    .text-blue {
        color: #2c1b5f !important;
    }

    .bg-blue {
        background-color: #2c1b5f !important;
    }

    .border-blue {
        border-color: #2c1b5f;
    }

    .fs-20px {
        font-size: 20px !important;
    }

    .h-40px {
        height: 40px !important;
    }

    .body-container {
        &.desktop {
            position: relative;
            z-index: 10;
        }

        .main-container {
            .title-container {
                margin-bottom: calc(3rem - 10px);

                h1 {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 46px;
                    color: #2c1b5f;

                    margin-bottom: calc(3rem - 18px);
                }

                h3 {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 24px;
                }
            }

            .search-container {
                font-family: 'Poppins', sans-serif;
                margin-bottom: 3rem;

                #input-search-address {
                    position: relative;

                    input {
                        border: 1px solid #2c1b5f !important;
                        border-radius: 3rem !important;
                        font-size: 20px !important;
                        text-align: center;

                        &:focus {
                            border-color: #2c1b5f;
                            box-shadow: rgba(44, 27, 95, 0.25) 0px 0px 0px 3.5px;
                        }

                        &::placeholder {
                            color: #312f32;
                            opacity: 0.5;
                        }
                    }
                }

                ::ng-deep button {
                    height: 100% !important;
                }

                .dropdown-menu {
                    border-radius: 1rem;
                    width: 100%;

                    &.show {
                        padding-bottom: 0;

                        &:has(.no-results) {
                            padding-top: 0;
                        }
                    }

                    .dropdown-item {
                        background-color: transparent;
                        border-width: 0;
                        font-family: inherit;
                        font-size: 14px;
                        font-style: inherit;
                        font-weight: inherit;
                        line-height: inherit;
                        padding: 3px 20px;
                        width: 100%;
                        text-align: left;
                        color: var(--base03);

                        text-overflow: ellipsis;
                        white-space: normal;

                        &.active {
                            background-color: gray !important;
                            color: var(--white) !important;
                        }
                    }

                    button:has(.is-disabled) {
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;

                        color: #2c1b5f;
                        pointer-events: none;
                        background-color: transparent;

                        .is-disabled {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            > small:first-child {
                                margin-right: 2.5rem;
                            }

                            .dot-pulse,
                            .dot-pulse::after,
                            .dot-pulse::before {
                                background-color: #2c1b5f;
                                color: #2c1b5f;
                                width: 4px;
                                height: 4px;
                            }
                        }
                    }
                }
            }

            .information-container {
                border: 2px solid #e77704;
                border-radius: 1rem;
                font-family: 'Poppins', sans-serif;
            }
        }
    }

    .illustration-container {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        .main-container {
            margin-right: 10rem;

            .title-container,
            .search-container {
                margin-right: 9rem;
            }
        }

        .illustration-container {
            display: block;
            position: relative;
            width: calc(300px + 70px + 2 / 3 * 180px);

            img {
                width: 300px !important;
                // max-width: 100%;
                height: 100% !important;
                border-radius: 1rem;
                transform: translateX(-70px);
            }

            .orange-square {
                width: 140px;
                height: 140px;
                position: absolute;
                right: 0;
                top: 0;
                transform: translate(0, -50%);
                border-radius: 1rem;
                background-color: #e77704;
                border: 2px solid #e77704;
                z-index: 1;
            }

            .white-square {
                width: 180px;
                height: 180px;
                position: absolute;
                left: -70px;
                top: calc(140px / 2);
                transform: translateX(-67%);
                border-radius: 1rem;
                background-color: white;
                border: 2px solid #e77704;
            }
        }
    }
}

[data-ui='mobile'] #login-sydesl {
    height: 100dvh;

    .top-background-sydesl {
        height: initial;
        background-image: url('/assets/img/login/background_sydesl.png');
        background-repeat: no-repeat;
        background-size: 1100px 500px;
        background-position: left -30px top 0px;
    }

    .bottom-background-sydesl {
        background-image: url('/assets/img/login/background_sydesl_bottom_mobile.png');
        background-repeat: no-repeat;
        background-size: 1100px 300px;
        background-position: right bottom;
    }

    .main-container {
        margin-right: 0;

        position: absolute;
        top: 25%;

        .title-container {
            margin-right: 0;

            h1 {
                font-size: 29px;
            }

            h3 {
                font-size: 16px;
            }
        }

        .search-container {
            margin-right: 0;

            #input-search-address {
                input {
                    font-size: 14px !important;
                    height: 40px;
                }
            }

            ::ng-deep button {
                height: 40px !important;
            }
        }
    }
}
