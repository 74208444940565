.title h3 {
    line-height: normal;
}

.btn-outline-bdr {
    background-color: transparent;
    color: var(--active) !important;
    border-color: var(--active) !important;
    outline: none !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.btn-outline-bdr:focus,
.btn-outline-bdr:hover {
    background-color: transparent;
    color: var(--active) !important;
    border-color: var(--active);
    outline: none !important;
}
