#header-antony .title {
    color: #001e57;
    font-family: 'Euclid Circular A', sans-serif;
    cursor: default;
}

#header-antony h2 {
    margin-top: 0;
    margin-bottom: 0;
}

#header-antony h4 {
    margin-bottom: 0;
    color: #001e57 !important;
}
