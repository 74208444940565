#header-cadastre-martinique {
    background-color: white !important;
    box-shadow: 0 2px 4px #00000026 !important;

    h1.navbar-brand {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px !important;
        text-wrap: balance;
    }
}

[data-ui='mobile'] #header-cadastre-martinique {
    h1.navbar-brand {
        font-size: 20px !important;
        padding-bottom: 0;
        margin-right: 0;
    }
}
