/* stop exclusion */
.bg-light-blue {
    background-color: rgb(55, 169, 224) !important;
}

.iconeClose {
    padding: 5px;
    cursor: pointer;
}

.main-navbar {
    box-shadow: 0px 2px 4px rgb(0 0 0 / 15%) !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

span.selection {
    left: 50%;
    transform: translateX(-50%);
}

.collapse-header-icon {
    color: var(--info) !important;
}

.ed-logo-link {
    background-color: transparent !important;
}

.siterre-logo {
    border-bottom-right-radius: 0.5rem;
    padding-left: 35px;
    padding-right: 35px;
}

.header-logo img {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.header-link a {
    color: var(--base07) !important;
    fill: var(--base07) !important;
    background-color: transparent !important;
    text-shadow: none !important;
    cursor: pointer;
}

.header-link a:hover {
    color: var(--active) !important;
    fill: var(--active) !important;
}

.header-link a svg {
    font-size: 1.5em;
}

.header-link a svg {
    font-size: 1.5em;
}

#header-siterre-cadastre-morbihan .header-link a {
    color: var(--info) !important;
}

#siterre-navbar-collapse .navbar-right {
    max-width: 40%;
}

.maxw-80 {
    max-width: 80% !important;
}

.maxw-75 {
    max-width: 75% !important;
}

.maxw-70 {
    max-width: 70% !important;
}

.maxw-60 {
    max-width: 60% !important;
}

.maxw-40 {
    max-width: 40% !important;
}

.maxw-30 {
    max-width: 30% !important;
}

.maxw-25 {
    max-width: 25% !important;
}

.maxw-20 {
    max-width: 20% !important;
}

.truncate {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-yellow-to-red {
    background: linear-gradient(270deg, #d12e33 0%, #f4c74b 100%) !important;
}

.main-navbar b {
    font-weight: 600;
}
