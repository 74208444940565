.login-card {
    border: solid 1px lightgrey;
    border-radius: 5px;
    background-color: var(--base01) !important;

    /* margin-bottom: 2rem; */
}

.stop-img img {
    background-color: var(--white);
    border-radius: 0.5rem;
}

.stop-exclustion-contact a {
    color: #337ab7;
}

.stop-exclustion-contact a:hover {
    color: #23527c;
}
