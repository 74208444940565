#header-cartocopro #territory-selection {
    li {
        position: relative;

        &.pseudo-center {
            transform: translateX(calc((115px + 118px + 227px - (193.5px + 1rem)) * 0.5));
        }
    }

    button {
        padding-right: 2rem !important;

        &:after {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: '';
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;

            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translate(50%, -50%);
        }
    }
}

#renovons-collectif-logo {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

#header-cartocopro .call-to-action {
    text-decoration: none !important;
    padding: 0.5rem 1.25rem;
    background-color: rgb(55, 169, 224) !important;
}

#header-cartocopro .call-to-action:hover {
    background-color: var(--active) !important;
}
