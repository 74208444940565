prosperReseaux .module-body {
    overflow: hidden !important;
}

#pr-element-title .pr-card {
    padding-top: 0;
    padding-bottom: 0;
}

prosperReseaux .pr-card {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
    /* border-bottom: 1px solid #dee2e6; */
    border-radius: 0.25rem;
    background-color: var(--base00);
    padding: 0.75rem;
    text-align: justify;
}

#pr-options {
    border-bottom: 1px solid #c5c5c5;
    display: flex;
    align-items: center;
}

prosperReseaux .element-options {
    z-index: 5;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(25px + 1rem);
}

prosperReseaux #main-accordion {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

prosperReseaux .accordion-item:not(:last-child) {
    padding-bottom: 0.25rem;
}

prosperReseaux .accordion-header {
    position: relative;
}

/* prosperReseaux .accordion-button {
    font-size: inherit;

    padding-bottom: 7.5px !important;
    padding-left: 12.5px !important;
    padding-right: 12.5px !important;
    padding-top: 7.5px !important;

    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 2.75px !important;
    border-top-right-radius: 2.75px !important;
} */

/* prosperReseaux .accordion-body {
    padding: 0px !important;
} */

prosperReseaux .accordion-button.element-type {
    background-color: var(--primary);
    color: var(--base00);
}

prosperReseaux .accordion-button.element-type.active {
    background-color: var(--active);
}

prosperReseaux .accordion-button.element-type::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

prosperReseaux .accordion-button.element-label {
    background-color: var(--base02-light);
    color: var(--base03);
}

prosperReseaux .accordion-button.element-label.active {
    color: var(--active);
}

prosperReseaux .accordion-button.element-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

prosperreseaux .form-group {
    margin-bottom: 10px;
}

prosperreseaux .form-group:last-child {
    margin-bottom: 0;
}

prosperReseaux .hypothesis-field {
    display: flex;
    align-items: center !important;
    margin-bottom: 6px;
}

prosperReseaux .parameter-field {
    display: flex;
    align-items: center !important;
    margin-bottom: 6px;
}

prosperReseaux .form-title {
    /* font-size: initial; */
    color: var(--primary) !important;
}

prosperReseaux input {
    height: 30px !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

prosperReseaux input::placeholder {
    font-style: italic;
}

prosperReseaux select {
    height: 30px !important;
    line-height: 1.3 !important;
}
