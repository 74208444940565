@font-face {
    font-family: 'Raleway';
    src: url('/assets/fonts//Raleway/Raleway-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('/assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

.fs-20px {
    font-size: 20px;
}

.fs-16px {
    font-size: 16px;
}

#login-cap-azur {
    min-height: 100dvh;
    font-family: 'Raleway', sans-serif;
    color: white;

    > .big-screen {
        min-height: 100dvh;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        background-image: url('/assets/img/login/csgp_cap_azur/background.png');
        background-repeat: no-repeat;
        background-size: cover;

        .content {
            margin: 0 250px;
            padding: 35px 50px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
                drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            border-radius: 4px;

            background: rgba(0, 0, 0, 0.6);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(0.75px);

            .body {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 30px;

                h1 {
                    margin-bottom: 0;
                    line-height: 56px;
                    font-size: 40px;
                    text-align: center;
                    font-weight: 700;
                }

                .description {
                    text-align: center;
                    font-size: 22px;
                    font-weight: 500;
                }

                .search-address {
                    width: calc(850 / 1020 * 100%);
                    display: flex;
                    gap: 23px;

                    .input-container {
                        border-radius: 4px;
                        position: relative;

                        input {
                            background-color: var(--white) !important;
                            font-size: 20px !important;
                            height: 40px;
                            border-radius: 4px;
                            border-color: #c8421b;
                            color: #c8421b;
                            text-align: center;

                            &::placeholder {
                                text-align: center;
                                color: #c8421b;
                                font-size: 20px;
                                opacity: 60%;
                            }
                        }

                        .dropdown-menu {
                            border-radius: 4px;
                            width: 100%;

                            &.show {
                                padding-bottom: 0;

                                &:has(.no-results) {
                                    padding-top: 0;
                                }
                            }

                            .dropdown-item {
                                background-color: transparent;
                                border-width: 0;
                                font-family: inherit;
                                font-size: 14px;
                                font-style: inherit;
                                font-weight: inherit;
                                line-height: inherit;
                                padding: 3px 20px;
                                width: 100%;
                                text-align: left;
                                color: var(--base03);

                                text-overflow: ellipsis;
                                white-space: normal;

                                &.active {
                                    background-color: gray !important;
                                    color: var(--white) !important;
                                }
                            }

                            button:has(.is-disabled) {
                                padding-top: 0.5rem;
                                padding-bottom: 0.5rem;

                                color: #c8421b;
                                pointer-events: none;
                                background-color: transparent;

                                .is-disabled {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    > small:first-child {
                                        margin-right: 2.5rem;
                                    }

                                    .dot-pulse,
                                    .dot-pulse::after,
                                    .dot-pulse::before {
                                        background-color: #c8421b;
                                        color: #c8421b;
                                        width: 4px;
                                        height: 4px;
                                    }
                                }
                            }
                        }
                    }

                    .btn-container {
                        width: 214px;
                        height: 40px;
                        background-color: #c8421b !important;
                        border: 1px solid #c8421b;
                        border-radius: 4px;
                        white-space: nowrap;

                        ::ng-deep button {
                            font-size: 20px;
                        }
                    }
                }

                .logos {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 30px 20px;
                    width: 100%;

                    background-color: white;
                    border-radius: 4px;
                    padding: 8px 50px;
                }
            }

            .footer {
                text-align: center;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    > .small-screen {
        height: 100dvh;

        display: flex;
        align-items: center;
        justify-content: center;

        .carousel-container {
            position: relative;
            .carousel-item img {
                object-fit: cover;
                height: 50vh; /* Adjust the height as needed */
                width: 100%;
            }
        }

        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;

            .body {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
                    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                border-radius: 4px;

                background: rgba(0, 0, 0, 0.6);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(0.75px);

                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 25px;

                padding-top: 25px;
                padding-bottom: 25px;

                h1 {
                    margin-bottom: 0;
                    line-height: 32px;
                    font-size: 24px;
                    text-align: center;
                    font-weight: 700;
                }

                .description {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                }

                .search-address {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    .input-container {
                        width: 100%;
                        border-radius: 4px;
                        position: relative;

                        input {
                            background-color: var(--white) !important;
                            font-size: 16px !important;
                            height: 40px;
                            border-radius: 4px;
                            border-color: #c8421b;
                            color: #c8421b;
                            text-align: center;

                            &::placeholder {
                                text-align: center;
                                color: #c8421b;
                                font-size: 16px;
                                opacity: 60%;
                            }
                        }

                        .dropdown-menu {
                            border-radius: 4px;
                            width: 100%;

                            &.show {
                                padding-bottom: 0;

                                &:has(.no-results) {
                                    padding-top: 0;
                                }
                            }

                            .dropdown-item {
                                background-color: transparent;
                                border-width: 0;
                                font-family: inherit;
                                font-size: 14px;
                                font-style: inherit;
                                font-weight: inherit;
                                line-height: inherit;
                                padding: 3px 20px;
                                width: 100%;
                                text-align: left;
                                color: var(--base03);

                                text-overflow: ellipsis;
                                white-space: normal;

                                &.active {
                                    background-color: gray !important;
                                    color: var(--white) !important;
                                }
                            }

                            button:has(.is-disabled) {
                                padding-top: 0.5rem;
                                padding-bottom: 0.5rem;

                                color: #c8421b;
                                pointer-events: none;
                                background-color: transparent;

                                .is-disabled {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    > small:first-child {
                                        margin-right: 2.5rem;
                                    }

                                    .dot-pulse,
                                    .dot-pulse::after,
                                    .dot-pulse::before {
                                        background-color: #c8421b;
                                        color: #c8421b;
                                        width: 4px;
                                        height: 4px;
                                    }
                                }
                            }
                        }
                    }

                    .btn-container {
                        width: 100%;
                        height: 40px;
                        background-color: #c8421b !important;
                        border: 1px solid #c8421b;
                        border-radius: 4px;
                        white-space: nowrap;

                        ::ng-deep button {
                            font-size: 20px;
                        }
                    }
                }

                .logos {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 0px 4px;
                    width: 100%;

                    background-color: white;
                    border-radius: 4px;
                    padding: 8px 10px;
                }
            }
        }

        // .sliding-background {
        //     width: 100%;
        //     height: 100%;
        //     background-size: 100% 50%, 100% 50%;
        //     background-position: top, bottom;
        //     background-repeat: no-repeat;
        //     animation: slide 10s infinite;

        //     background-image: url('/assets/img/login/csgp_cap_azur/top_left.jpg'),
        //         url('/assets/img/login/csgp_cap_azur/bottom_left.jpg');
        // }

        // @keyframes slide {
        //     0%,
        //     50% {
        //         background-image: url('/assets/img/login/csgp_cap_azur/top_left.jpg'),
        //             url('/assets/img/login/csgp_cap_azur/bottom_left.jpg');
        //     }
        //     50.1%,
        //     100% {
        //         background-image: url('/assets/img/login/csgp_cap_azur/top_right.jpg'),
        //             url('/assets/img/login/csgp_cap_azur/bottom_right.jpg');
        //     }
        // }
    }
}
